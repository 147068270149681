@charset "utf-8";
/*
 *  Princess Cruises CMS Styles
 *
 *  The purpose of this file is to house styles which are repeated throughout the CMS but not necessarily throughout the rest of princess.com
 */


/*
 * Breadcrumbs
 */
.breadcrumb-nav {
    background: var(--neutral-light);
    margin: 0 auto;
    padding: 5px 15px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 101;
}
.breadcrumb-item {
    display: inline-block;
    font-size: 1em;
    position: relative;
}
.breadcrumb-item + .breadcrumb-item:before {
    background: url("/images/global/sprite_pc-icons.png") no-repeat scroll -107px -42px;
    content: '';
    display: inline-block;
    height: 14px;
    margin: 0 10px;
    position: relative;
    top: 3px;
    width: 8px;
}

/* To handle wrapping of buttons, we're adding margin to the bottom of all inside the article container */
.article-container .button {
    margin-bottom: .5em;
}

/*
 * Play Button Overlay
 */
.play-btn {
    color: var(--neutral-white);
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    margin-left: -40px;
    margin-top: -30px;
}
.article-banner-primary .play-btn {
    margin-left: -150px;
    margin-top: -50px;
    text-align: center;
    width: 300px;
}
@media screen and (max-width: 26.1875em) {
    .article-banner-primary .play-btn {
        margin-left: -110px;
        width: 220px;
    }
}

.article-banner-secondary > a,
.article-banner-primary > a {
    display: block;
}
.article-banner-secondary > a > img,
.article-banner-primary > a > img {
    position: relative;
}
@media screen and (min-width: 60em) {
    .article-banner-primary .play-btn {
        margin-top: -80px;
    }
}
.play-btn .play-icon {
    background: var(--transparent-medium);
    border: 2px solid var(--neutral-white);
    border-radius: 50%;
    display: block;
    height: 60px;
    margin: 0 auto;
    position: relative;
    text-indent: -999em;
    width: 60px;
}
.no-rgba .play-btn .play-icon {
    background: var(--neutral-black);
    opacity: .45;
}
.play-btn .play-icon:before {
    border: 0 solid transparent;
    border-bottom-width: 11px;
    border-left-width: 18px;
    border-top-width: 11px;
    border-left-color: var(--neutral-white);
    content: ' ';
    font-size: 0;
    height: 0;
    left: 50%;
    line-height: 0;
    position: absolute;
    width: 0;
    top: 50%;
    margin-left: -5px;
    margin-top: -10px;
}
.play-label {
    display: none;
}
.article-banner-primary .play-label {
    background: var(--transparent-medium);
    display: inline-block;
    margin-top: .5em;
    padding: 1px .5em;
}
.article-banner-primary .play-btn {
    z-index: 1;
}
a:hover .play-btn, a:focus .play-btn, a:active .play-btn {
    text-decoration: underline;
}
a:hover .play-btn .play-icon, a:hover .play-btn .play-label,
a:focus .play-btn .play-icon, a:active .play-btn .play-label {
    background: var(--transparent-medium);
}
.no-rgba a:hover .play-btn .play-icon,
.no-rgba a:focus .play-btn .play-icon,
.no-rgba a:active .play-btn .play-icon {
    opacity: .8;
}
@media screen and (min-width: 30em) {
    .play-btn {
        margin-top: -40px;
    }
    .play-btn .play-icon {
        height: 80px;
        width: 80px;
    }
    .play-btn .play-icon:before {
        border-bottom-width: 17px;
        border-left-width: 30px;
        border-top-width: 17px;
        margin-left: -10px;
        margin-top: -17px;
    }
}


/*
 * Sticky bar
 */
.sticky-wrapper, .cta-wrapper {
    background: var(--neutral-white);
    position: relative;
    padding: 15px 0;
}
.sticky-wrapper.no-padding, .cta-wrapper.no-padding {
    padding: 0;
}
.sticky-wrapper:first-child, .cta-wrapper:first-child {
    margin-top: 0;
}
.sticky-bar, .cta-wrapper > .cta-bar {
    background:var(--primary-blue-dark);
    font-size: 1.357142857142857em; /* 19px */
    margin: 0 auto;
    padding: .5em 0;
    position: relative;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 900;
}
.cta-wrapper > .cta-bar.sticky {
    z-index: 2000;
}
.sticky-bar > .button, .cta-wrapper > .cta-bar > .button {
    margin: .5em 1em;
}
.sticky-bar > .special-offers-click, .sticky-bar > .special-offers-click + .button,
.cta-wrapper > .cta-bar > .special-offers-click, .cta-wrapper > .cta-bar > .special-offers-click + .button {
    visibility: hidden;
}
.full-width .sticky-wrapper > .sticky-bar:before,
.full-width .cta-wrapper > .cta-bar:before {
    background:var(--primary-blue-dark);
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
@media screen and (min-width: 61.875em) {
    .full-width .sticky-wrapper > .sticky-bar:before,
    .full-width .cta-wrapper > .cta-bar:before {
        left: 50%;
        margin-left: -50%; /* for browsers that don't support calc, we're setting the margin-left to -50% to offset the left position */
        margin-left: calc(-100vw / 2);
        max-width: 1700px;
        width: 100vw;
    }
}
@media screen and (min-width: 106.25em) {
    .full-width .sticky-wrapper > .sticky-bar:before,
    .full-width .cta-wrapper > .cta-bar:before {
        margin-left: -850px;
    }
}
@media screen and (min-width: 48em) {
    .sticky-bar.fixed, .cta-wrapper > .cta-bar.fixed {
        -webkit-box-shadow: 0px 5px 0px 0px var(--transparent-medium);
        -moz-box-shadow: 0px 5px 0px 0px var(--transparent-medium);
        box-shadow: 0px 5px 0px 0px var(--transparent-medium);
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        max-width: 990px;
    }
    .full-width .sticky-wrapper > .sticky-bar.fixed,
    .full-width .cta-wrapper > .cta-bar.fixed {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    .full-width .sticky-wrapper > .fixed:before,
    .full-width .cta-wrapper > .fixed:before {
        -webkit-box-shadow: 0px 5px 0px 0px var(--transparent-medium);
        -moz-box-shadow: 0px 5px 0px 0px var(--transparent-medium);
        box-shadow: 0px 5px 0px 0px var(--transparent-medium);
    }
    .mfp-wrap + #container .sticky-bar.fixed,
    .mfp-wrap + #container .cta-bar.fixed {
        right: 16px;
    }
    .sticky-bar.stop, .cta-wrapper > .cta-bar.stop {
        position: absolute;
        bottom: 0;
        top: auto;
    }
}
/* Custom variation for Excursion Sticky Bar */
.excursion-bar {
    font-size: 1em;
}
.excursion-bar .form-field > select {
    width: 100%;
}
.excursion-bar .already-booked, .excursion-bar .already-booked-link {
    display: block;
    padding-bottom: 5px;
    float: left;
}
.excursion-bar .already-booked {
    margin-right: 1em;
}
@media (min-width: 640px) and (max-width: 767px) {
    .excursion-bar .col-xs-12 + .row {
        clear: left;
    }
}


/*
 * Article Banner
 */
/* Default article-banner text styles */
.article-banner-content > .article-title-h1 {
    font-size: 2.285714285714286em;
    margin-bottom: 0;
    text-transform: none;
}
.article-banner-content > .article-title-h2 {
    margin-top: .45em;
    font-size: 1.142857142857143em;
    letter-spacing: 0.1px;
}
.article-banner-content > .article-title-h1, .article-banner-content > .article-title-h2 {
    line-height: 1.05;
    text-align: center;
}
.article-banner-content > .article-nav .anchor-btn {
    display: inline-block;
    font-family: var(--red-hat-text-it);
    font-size: 0.928571428571429em;
    padding: 0.923076923076923em 1.153846153846154em 0.461538461538462em 0;
    white-space: normal;
}

/* Default layout styles - primarily for legacy browsers not supporting CSS Grid */
.article-banner {
    background-color: var(--neutral-white);
    box-shadow: 0 2px 7px 0 var(--transparent-medium);
    position: relative;
    z-index: 1;
}

.article-banner-primary, .article-banner-secondary {
    float: left;
}
.article-banner-primary {
    width: 75%;
}
.article-banner-secondary {
    width: 25%;
}
.article-banner-content {
    background-color: var(--neutral-white);
    clear: both;
    padding: 2.142857142857143em;
}
.article-banner .article-nav {
    margin-left: auto;
    margin-right: auto;
    max-width: 44em;
    padding: 0.9375em;
    width: 100%;
}
@media screen and (max-width: 59.9375em) {
    .article-banner {
        box-shadow: none;
    }
    .article-banner-content {
        box-shadow: inset 0 4px 7px -4px var(--neutral-black);
        padding-bottom: 0;
    }
}

/* For mobile users, the article-nav behaves as a drawer */
@media (max-width: 47.9375em) {
    .btn-whats-on-page {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .btn-whats-on-page > .svg-icon {
        height: 0.928571428571429em;
        margin-left: .5em;
        transform: rotate(90deg);
        width: 0.928571428571429em;
        vertical-align: middle;
    }
    .btn-whats-on-page[aria-expanded="true"] > .svg-icon {
        transform: rotate(-90deg);
    }
    .article-anchor-drawer {
        display: none;
    }
    .article-anchor-drawer.expanded {
        display: block;
    }
}

/* For larger screen mobile devices through desktop, display the article-nav in 2 or 3 column layouts */
@media (min-width: 26.25em) {
    .article-banner-content > .article-nav ul {
        column-count: 2;
        column-gap: 0.9375em;
    }
}
/* 3 column for tablet only */
@media (min-width: 40em) and (max-width: 59.9375em) {
    .article-banner-content > .article-nav ul {
        column-count: 3;
    }
}

/* For desktop users between 960 - 1072, we need to reduce padding to fit the worse case scenario of text content */
@media screen and (min-width: 60em) and (max-width: 66.9375em) {
    .article-banner-content {
        padding-bottom: 1.25em;
        padding-right: .9375em;
        padding-top: 1.25em;
    }
}

/* For legacy browsers that do not support CSS Grid, align the top banner elements to mimic the CSS Grid.
   Note, vertical alignment will be based on the top (not centered) */
@media (min-width: 60em) {
    .article-banner-content > .article-title-h1, .article-banner-content > .article-title-h2 {
        text-align: left;
    }
    .article-banner-primary {
        left: 40%;
        position: relative;
        width: 60%;
    }
    .article-banner-secondary {
        display: none;
    }
    .article-banner-content {
        clear: none;
        float: left;
        position: relative;
        right: 60%;
        width: 40%;
    }
}

@media screen and (min-width: 80em) {
    .article-banner-primary {
        left: 33.33333333333333%;
        width: 50%;
    }
    .article-banner-secondary {
        display: block;
        width: 16.666666666666664%;
    }
    .article-banner-content {
        right: 50%;
        width: 33.33333333333333%;
    }
}
/* Browsers that support CSS Grid */
@supports (display: grid) {
    /* wipe out width settings previously set */
    .article-banner > div {
        width: 100%;
    }

    /* Grid for mobile and tablet devices */
    .article-banner {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: min-content min-content;
    }
    .article-banner-secondary {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    .article-banner-primary {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        left: 0;
    }
    .article-banner-content {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        right: 0;
    }

    /* Grid for desktop users between 960 - 1279px */
    @media screen and (min-width: 60em) and (max-width: 79.9375em) {
        .article-banner {
            grid-template-columns: minmax(30em, 40%) 60%;
            grid-template-rows: min-content;
        }
        .article-banner-secondary {
            display: none;
            visibility: hidden;
        }
        .article-banner-primary {
            grid-column: 2 / 3;
        }
        .article-banner-content {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            align-self: center;
            right: 0;
        }
    }

    /* Grid for desktop users between 1280px */
    @media screen and (min-width: 80em) {
        .article-banner {
            grid-template-columns: 1fr minmax(30em, 2fr) 3fr;
            grid-template-rows: min-content;
        }
        .article-banner-secondary {
            display: block;
            visibility: visible;
        }
        .article-banner-primary {
            grid-column: 3 / 4;
        }
        .article-banner-content {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            align-self: center;
            right: 0;
        }
    }
}

/*
 *  Slideshow for images and videos
 */
.slideshow {
    position: relative;
    margin: 0 auto;
    width: 100%;
    /*max-width: 640px;*/
}
.slideshow img {
    width: 100%;
    border: 1px solid var(--neutral-cool-medium);
}
.slideshow-row.not-ready > .slideshow-controls, .slideshow-row.not-ready > .slideshow-nav {
    display: none;
}
.slideshow {
    overflow: hidden;
    position: relative;
    width: 100%;
}
.slideshow-item {
    overflow: hidden;
    position: relative;
    padding: 0;
    text-align: center;
    width: 100%;
}
.slideshow-thumb-nav img, .video-thumb-link {
    cursor: pointer;
}
.slideshow-thumb-nav img, .video-thumb-link img{
    border: 2px solid var(--neutral-cool-medium);
}
.slideshow-thumb-link {
    display: block;
}
.cycle-pager-active img, .video-thumb.active img {
    border: 2px solid var(--caution);
}
.slideshow-caption {
    z-index: 200;
}

/* Duplicate Styles need to clean up */
.slideshow-controls, .video-controls {
    background: var(--primary-blue);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#AA0054a0', endColorstr='#AA0054a0');
    border: 0;
    cursor: pointer;
    display: block;
    height: 64px;
    margin-top: -32px;
    padding: 5px;
    position: absolute;
    top: 27%;
    width: 29px;
    z-index: 150;
}
.slideshow-controls:hover, .slideshow-controls:hover,
.slideshow-controls:focus, .slideshow-controls:focus  {
    background: var(--primary-blue);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#AA42729e', endColorstr='#AA42729e');
}
.slideshow-prev, .video-prev {
    left: -44px;
}
.slideshow-next, .video-next {
    right: -44px;
}
.slideshow-controls .arrow, .video-controls .arrow {
    background-image: url(/images/home/arrows.png);
    height: 100%;
    width: 100%;
    margin: 0;
}
.slideshow-prev .arrow, .video-prev .arrow {
    background-position: top left;
}
.slideshow-next .arrow, .video-next .arrow {
    background-position: top right;
}
@media screen and (max-width: 767px) {
    .slideshow-controls, .video-controls {
        display: none;
    }

}
@media screen and (min-width: 768px) {
    .slideshow-controls, .video-controls {
        display: block;
        top: 50%;
    }
}


/*
 * Quote
 */
.quote {
    margin: 1em 0;
    padding: 20px 15px 25px;
}
.quote > .line1, .quote > .line2 {
    display: block;
}
.quote > .line1 {
    font-size: 1.428571428571429em; /* 20px */
}
@media (min-width: 640px) {
    .quote > .line1 {
        font-size: 1.714285714285714em; /* 24px */
    }
}

/*
 * LTO Banner
 */
.lto-banner-link {
    display: block;
    padding: 10px;
}

/*
 * Floating Cards
 */
.article-container > .floating-cards:first-child {
    margin-top: -15px;
}
.floating-cards {
    background: var(--neutral-light);
}
.floating-cards.white-bg {
    background: var(--neutral-white);
}
.floating-cards .card {
    background: var(--neutral-white);
    border-bottom: 1px solid var(--neutral-medium);
    width: 100%;
}
.floating-cards .card-col + .card-col > .card {
    border-top: 1px solid var(--neutral-medium);
}
@media screen and (min-width: 640px) {
    .floating-cards .card {
        border: 1px solid var(--neutral-medium);
    }
}
.floating-cards .card-buttons {
    text-align:center;
}
.floating-cards .card-buttons > .button {
    margin: 0 .25em;
    min-width: 155px;
    padding-left: 1.2em;
    padding-right: 1.2em;
    text-align: center;
}
.floating-cards .card-buttons > .button + .button {
    margin-top: .5em;
}

/*
 * Image Grid
 */
.img-grid {
    background: var(--neutral-light);
    position: relative;
}
.img-grid-title {
    background: var(--primary-blue-dark);
    color: var(--neutral-white);
    margin: 0;
    padding: .25em 1.25em;
    position: relative;
    text-align: center;
    width: 100%;
}
.no-rgba .img-grid-title {
    background: var(--auxiliary-blue-dark);
}
.img-grid a {
    display: block;
    height: 100%;
    width: 100%;
}
.img-floating-caption {
    transition: padding .5s ease;
}
.img-grid a:hover > .img-floating-caption,
.img-grid a:focus > .img-floating-caption {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
}
.img-grid .img-floating-caption {
    bottom: 0;
    position: absolute;
}


/*
 * Call To Action for Get Brochures & Signup for Special Offers
 */
.cta-bar .cta-get-brochure {
    display: block;
    margin: 0 auto;
    max-width: 330px;
}
.cta-bar .cta-get-brochure > img {
    float: left;
    margin-right: .25em;
}
.cta-bar .cta-get-brochure > .line {
    display: block;
}
.cta-bar .cta-get-brochure > img + .line {
    margin-top: .5em;
}
@media screen and (min-width: 768px) {
    .cta-bar .cta-special-offers {
        border-left: 2px solid var(--neutral-cool-medium);
    }
}


/*
 * Floating image caption position at bottom of image with
 */
.img-floating-caption {
    background: var(--transparent-dark);
    display: block;
    color: var(--neutral-white);
    padding: .75em 1.25em;
    text-align: center;
    width: 100%;
}
.img-floating-caption.white {
    background: var(--transparent-light);
    color: var(--neutral-black);
}
.no-rgba .img-floating-caption {
    background: var(--neutral-black);
}
.no-rgba .img-floating-caption.white {
    background: var(--neutral-white);
}
@media screen and (min-width: 640px) {
    .img-floating-caption {
        bottom: 0;
        position: absolute;
    }
}


/*
 * ie8 work around to accommodate media queries
 */
.lt-ie9 .col-xs-12.float-right {
    float: right !important;
}


/*
 * LI separated by rules
 */
.horiz-li-divider li {
    padding: .25em 0;
}
.horiz-li-divider li + li {
    border-top: 1px solid var(--neutral-medium);
}


/*
 * Site Search in Article Header & Article Aside
 */
.tpl-v4 .article-header .site-search {
    padding: 0 15px;
}
.tpl-v4 .article-header .site-search-form span {
    padding-left: 0;
}
.tpl-v4 .article-header .site-search-form span label {
    left: .75em;
}
.article-aside .site-search {
    position: relative;
}
.article-header .site-search-form {
    margin: 0 auto;
    max-width: 640px;
    position: relative;
}
.article-aside .site-search-field {
    padding-right: .76922307em;
}
.article-header .site-search-field > input, .article-aside .site-search-field > input {
    padding: 6px 32px 6px 6px;
    width: 100%;
}
.article-header .site-search-btn, .article-aside .site-search-btn {
    border: 4px solid transparent;
    position: absolute;
    right: 0;
    width: 29px;
    height: 30px;
    background: url(/images/global/search-v2.svg) no-repeat transparent;
}
.article-aside .site-search-btn {
    right: 0.92307692307692em;
}

/*
 * Anchor Scroll
 */
.anchor-scroll-field label {
    vertical-align: middle;
}
.anchor-scroll-label {
    margin-right: .5em;
}
.anchor-scroll-field .anchor-scroll, .anchor-scroll {
    max-width: 100%;
}

/*
 * Ships : PDP
 */
.product-show-toggle {
    cursor: pointer;
    display: none;
}
.wide-features .product-show-toggle, .cards-features .product-show-toggle, .sbs-features .product-show-toggle {
    display: inline-block;
}
.product-close {
    display: none;
}
.wide-features .product-close, .cards-features .product-close, .sbs-features .product-close {
    display: inline-block;
}
.product-show-toggle:after, .product-close:after {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--primary-blue);
    display: inline-block;
    height: 0;
    position: relative;
    margin-left: 10px;
    width: 0;
}
.product-show-toggle.active:after, .product-close:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--primary-blue);
    border-top: 0;
}
.product-gallery-buttons {
    padding: 0 10px;
    border: none;
    background: transparent;
}
.product-gallery-options .slideshow {
    max-width: inherit;
}
.product-gallery-options .slideshow-container {
    position: relative;
    font-size: 0;
}
.product-gallery-options .slideshow-thumb-nav-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 101;
    text-align: center;
}
.product-gallery-options .slideshow-thumb-nav {
    background: var(--transparent-light);
    display: inline-block;
}
.product-gallery-options .slideshow-thumb-nav > div, .product-gallery-options .slideshow-thumb-nav > button{
    display: inline-block;
    margin: 0 7px;
    width: 12px;
    height: 12px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    background: var(--neutral-dark);
    opacity: .8;
    box-shadow: 2px 2px 4px var(--transparent-medium);
    border: 0;
}
.product-gallery-options .slideshow-thumb-nav > button.cycle-pager-active {
    background: var(--neutral-white);
    opacity: 1;
}
.product-gallery-options .slideshow-thumb-nav > button:hover, .product-gallery-options .slideshow-thumb-nav > button:focus {
    background: var(--neutral-medium);
    opacity: .8;
}
.product-gallery-options .slideshow-thumb-nav img, .video-thumb-link img {
    border:0;
}
.product-gallery-options .cycle-pager-active img, .product-gallery-options .slideshow-thumb-nav img:hover, .product-gallery-options .slideshow-thumb-nav img:focus, .video-thumb.active img {
    border: 2px solid var(--neutral-white);
    box-shadow: 1px 1px 5px var(--transparent-dark);
}
.product-gallery-options.not-ready {
    visibility: hidden;
}
.product-flags {
    margin-left: 1%;
    position: absolute;
    width: 100%;
    z-index: 101;
}
.product-flags > div {
    width: 50px;
    height: 50px;
    margin: auto;
}
.flags-small {
    margin-left: 2%;
}
.product-gallery-options .slideshow-thumb-nav {
    background: none;
    padding: 0;
}
.product-gallery-options .slideshow-thumb-nav img, .video-thumb-link img {
    visibility: hidden;
}
.product-gallery-options .cycle-pager-active img, .video-thumb.active img {
    border: none;
    box-shadow: none;
}
.product-gallery-buttons {
    padding: 0 5px;
}
.product-options-photo .slideshow-thumb-nav > .img-responsive-wrapper {
    cursor: pointer;
}

@media (min-width:40em) {
    /* Media Query : Desktop */
    .product-gallery-buttons > p, .product-gallery-buttons > img {
        display: inline-block;
        vertical-align: middle;
    }
}
@media (max-width: 39.9375em) {
    /* Media Query : Mobile */
    .wide-features .product-close, .cards-features .product-close, .sbs-features .product-close {
        display: none;
    }
}


/*
 * Ships : Facts
 */
.ship-overview > div, .ship-overview > div + div div {
    display: inline-block;
}
.ship-overview hr {
    margin: .75em 0;
}
.ship-info ul.no-list-style {
    padding-left: 15px;
}
.ship-show-toggle {
    cursor: pointer;
    display: inline-block;
}
.ship-show-toggle:after, .ship-close button:after {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--primary-blue);
    display: inline-block;
    height: 0;
    position: relative;
    margin-left: 10px;
    width: 0;
}
.ship-show-toggle.active:after, .ship-close button:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--primary-blue);
    border-top: 0;
}
@media (max-width: 39.9375em) {
    /* Media Query : Mobile */
    .ship-info .ship-info-column {
        border-top: none;
    }
    .ship-info > div:first-child .ship-info-column, .ship-features div {
        border-bottom: none;
    }
    .ship-info hr, .ship-features hr {
        display: block;
        margin: 2em 0 0 0;
    }
    .ship-overview hr {
        visibility: hidden;
    }
    .ship-overview > div + div {
        padding-top: 20px;
        text-align: center;
        width: 100%;
    }
    .ship-overview > div + div > hr + div > div {
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    /* one item */
    .ship-overview > div + div > hr + div > div:nth-child(1):nth-last-child(1) {
        width: 100%;
    }
    /* two items */
    .ship-overview > div + div > hr + div > div:nth-child(1):nth-last-child(2),
    .ship-overview > div + div > hr + div > div:nth-child(2):nth-last-child(1) {
        width: 50%;
    }
    /* three items */
    .ship-overview > div + div > hr + div > div:nth-child(1):nth-last-child(3),
    .ship-overview > div + div > hr + div > div:nth-child(2):nth-last-child(2),
    .ship-overview > div + div > hr + div > div:nth-child(3):nth-last-child(1) {
        width: 33.33333333333333%;
    }
    .ship-overview > div + div > div {
        width: 100%;
    }
    .ship-overview > div + div > div:first-child > div {
        width: 33.33333333333333%;
        float: left;
    }
    .ship-overview > div + div > hr + div > div span {
        display: block;
    }
    .ship-overview > div + div > hr + div > div + div  {
        border-left: 1px solid var(--neutral-medium);
    }
    .ship-overview > div + div > hr + div > div:last-of-type {
        width: 100%;
        float: none;
    }
    .ship-overview .svg-icon.svg-icon-deckplan, .ship-overview .svg-icon.svg-icon-bridge-cam, .ship-overview .svg-icon.svg-icon-find-cruise {
        display: block;
        margin: 0 auto .35em auto;
        width: 30px;
        height: 30px;
    }
}


/*
 * Ships : Wide Module
 */
.wide-media>ul>li.active {
    background-color: var(--neutral-cool-light);
}
.wide-media>ul>li+li>div:first-of-type,
.wide-media>ul>li.active>div:first-of-type {
    border-top: 1px solid var(--neutral-dark);
}
.wide-media>ul>li.active>div:first-of-type,
.wide-media>ul>li.active+li>div:first-of-type {
    margin: 0;
}
.wide-media>ul>li.active>div:first-of-type a div,
.wide-media>ul>li.active+li>div:first-of-type a div {
    margin: 0 2em;
}
.wide-media>ul>li>.wide-nav-link {
    margin: 0 2em;
    padding: .5em 0;
    text-align: left;
}
.wide-nav-link span:not(.coming-soon):after {
    position: absolute;
    right: 2em;
    display: inline-block;
    width: 0;
    height: 0;
    margin-top: .5em;
    content: '';
    border-top: 8px solid var(--primary-blue);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
}
.wide-nav-link a span {
    display: inline-block;
    padding-right: 1.8em;
    width: 100%;
}
.wide-media>ul>li.active .wide-nav-link span:not(.coming-soon):after {
    border-top: 0;
    border-bottom: 8px solid var(--primary-blue);
}
.wide-nav-content {
    display: block;
}
.wide-nav-close {
    position: relative;
}
.wide-nav-close a {
    /* turn off - PCSIWSC-1294 */
    display: none;
}
.wide-nav-link a,
.wide-nav-close a {
    text-decoration: none;
}
.transparent-tab-nav .tab-nav-item.active>.tab-nav-link {
    color: var(--neutral-white);
    background-color: var(--neutral-dark);
}
.wide-wrapper,
.wide-wrapper>.container {
    position: relative;
}
.wide-intro-summary {
    padding: .5em 2.8em 1.6em;
}
.wide-intro-summary > p {
    margin: 0;
}
@media (min-width:40em) {
    /* Media Query : Desktop */
    .wide-wrapper {
        background-color: var(--neutral-cool-light);
    }
    .wide-media>ul>li+li {
        border-top: none;
    }
    .wide-media>ul>li:not(.active) {
        display: none;
    }
    .wide-features ul {
        list-style-type: disc;
    }
    .wide-media-gallery {
        min-height: 416px;
    }
}
@media (min-width:62em) {
    /* Media Query : Large */
    .wide-media-gallery {
        min-height: 541px;
    }
}
@media (max-width:47.9375em) {
    /* Media Query : Tablet */
    .wide-media-gallery {
        min-height: 344px;
    }
}
@media (max-width: 39.9375em) {
    /* Media Query : Mobile */
    h2.wide-intro-header {
        background-color: var(--neutral-cool-light);
    }
    .wide-nav-close span:first-child:after {
        position: relative;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 10px;
        content: '';
        border-top: 0;
        border-right: 8px solid transparent;
        border-bottom: 8px solid var(--primary-blue);
        border-left: 8px solid transparent;
    }
    .wide-nav-content {
        display: none;
    }
    li.active .wide-nav-content {
        display: block;
    }
    .wide-media-gallery {
        min-height: 160px;
    }
    .wide-intro-summary {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
    }
    .wide-media > ul {
        margin-top: 0;
    }
}
@media screen and (min-width: 106.25em) {
    /* Media Query : X Large */
    .wide-wrapper:before {
        margin-left: -850px;
    }
}
.wide-media {
    position: relative;
}
h2.wide-intro-header {
    position: relative;
    padding-left: 1.6em;
    padding-right: 1.6em;
}
.wide-content {
    display: none;
}
.wide-overview>h2,
.wide-overview>p:first-child {
    margin-top: 0;
}
.wide-overview>h3.gotham-xn-book {
    letter-spacing: 2px;
}
li.active .wide-content {
    display: block;
}
.wide-media-gallery .not-ready .slideshow-item {
    /* this is to prevent stutter on init */
    position: absolute;
}

/*
 * Ships : Cards Module
 */
.cards-wrapper a {
    display: block;
    overflow: hidden;
}
.cards-wrapper li > a {
    display: inline;
}
.cards-wrapper .disclaimer > a {
    display: inline;
}
.cards-nav {
    margin-bottom: 1em;
}
.cards-nav-wrapper:nth-of-type(3n+1) {
    clear: left;
}
.cards-nav-content,
.cards-content {
    display: none;
}
.cards-nav-content:before,
.cards-content:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
}
.cards-nav-close {
    position: relative;
}
.cards-nav-close a {
    /* turn off - PCSIWSC-1294 */
    display: none;
}
.cards-nav-link a,
.cards-nav-close a {
    text-decoration: none;
    color: var(--primary-blue);
}
.cards-nav-wrapper.active > .cards-content {
    display: block;
}
.cards-overview > h2,
.cards-overview > p:first-child {
    margin-top: 0;
}
.cards-overview > h4.gotham-xn-book {
    letter-spacing: 2px;
}
.cards-wrapper, h2.cards-intro-header {
    position: relative;
}
.cards-nav-link h3 {
    margin: 0;
    color: var(--primary-blue);
    text-transform: none;
}
.cards-intro-summary {
    padding: .5em 2.8em 1.6em;
}
.cards-intro-summary > p {
    margin: 0;
}
h2.cards-intro-header {
    position: relative;
    padding-left: 1.6em;
    padding-right: 1.6em;
}
@media (min-width:40em) {
    /* Media Query : Desktop */
    .cards-overview {
        padding-left: 0;
    }
    .cards-features {
        float: left;
    }
    .cards-nav-thumb {
        position: relative;
    }
    .cards-nav-wrapper:hover .cards-nav-link div.cards-nav-thumb+div,
    .cards-nav-wrapper:focus .cards-nav-link div.cards-nav-thumb+div,
    .cards-nav-wrapper.active .cards-nav-link div.cards-nav-thumb+div,
    .cards-nav-link > a:focus div.cards-nav-thumb+div,
    .cards-nav-link > a:hover div.cards-nav-thumb+div > h3,
    .cards-nav-link > a:focus div.cards-nav-thumb+div > h3,
    .cards-nav-wrapper:hover .cards-nav-link > a div.cards-nav-thumb+div > h3,
    .cards-nav-wrapper.active .cards-nav-link > a div.cards-nav-thumb+div > h3 {
        color: var(--neutral-white);
        background-color: var(--neutral-dark);
    }
    .cards-nav-thumb .svg-icon-flag-new,
    .cards-nav-thumb .svg-icon-flag-exclusive,
    .cards-nav-thumb .svg-icon-flag-coming-soon {
        width: 50px;
        height: 50px;
    }
    .cards-nav-thumb img {
        min-height: 102px;
    }
    /* Display None when content open */
    .cards-nav-wrapper .cards-nav-thumb .product-flags {
        transition: opacity .15s;
        opacity: 1;
    }
    .cards-nav-wrapper.active .cards-nav-thumb .product-flags {
        opacity: 0;
    }
    .cards-nav-wrapper.active .cards-nav-link:after {
        position: absolute;
        left: 46.5%;
        display: inline-block;
        width: 0;
        height: 0;
        margin-top: -1px;
        content: '';
        border-top: 12px solid var(--neutral-dark);
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
    }
    .cards-nav-wrapper:hover .cards-nav-thumb:after,
    .cards-nav-wrapper:focus .cards-nav-thumb:after,
    .cards-nav-link > a:focus .cards-nav-thumb:after {
        position: absolute;
        z-index: 102;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--transparent-medium);
    }
    .cards-nav-wrapper.active .cards-nav-thumb:after {
        position: absolute;
        z-index: 102;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--transparent-medium);
    }
    .cards-nav-wrapper.active .cards-nav-link>a>div,
    .cards-nav-wrapper.active article {
        position: relative;
    }
    .cards-nav-wrapper .cards-nav-link div.open-close-icon svg {
        width: 30px;
        height: 30px;
        color: var(--neutral-white);
    }
    .cards-nav-wrapper .cards-nav-link div.open-close-icon {
        position: absolute;
        top: 45%;
        left: 50%;
        display: none;
    }
    .cards-nav-wrapper:hover .cards-nav-link div.open-close-icon,
    .cards-nav-wrapper:focus .cards-nav-link div.open-close-icon,
    .cards-nav-link > a:focus div.open-close-icon {
        z-index: 103;
        display: block;
        -webkit-transition: all .1s linear;
        -ms-transition: all .1s linear;
        transition: all .1s linear;
        -webkit-transform: translate(-50%, -45%) rotate(-45deg);
        -ms-transform: translate(-50%, -45%) rotate(-45deg);
        transform: translate(-50%, -45%) rotate(-45deg);
        background-color: transparent;
    }
    .cards-nav-wrapper.active .cards-nav-link div.open-close-icon {
        z-index: 103;
        display: block;
        -webkit-transform: translate(-50%, -45%);
        -ms-transform: translate(-50%, -45%);
        transform: translate(-50%, -45%);
        background-color: transparent;
    }
    .cards-nav-thumb+div {
        padding: .5em 0;
    }
    .cards-nav-thumb+div>span {
        font-size: 1.071428571428571em;
        /*15px*/
    }
    .cards-nav-content:before,
    .cards-content:before,
    .module-white-bg .cards-content:before {
        left: 50%;
        width: 100vw;
        max-width: 1700px;
        margin-left: -50%;
        margin-left: calc(-100vw / 2);
        background: var(--neutral-cool-light);
        box-shadow: inset 0 0 20px 7px var(--neutral-light);
    }
    /* dark */
    .module-grey-bg .cards-content:before {
        box-shadow: inset 0 0 20px 7px var(--neutral-medium);
    }
    .cards-nav-content,
    .cards-content {
        overflow: visible;
    }
    .cards-media-gallery {
        min-height: 345px;
    }
    .cards-nav-link>a>div {
        /* note: this was added for the slidedown animation; check to see if it's working on legacy cards */
        /* position: relative; */
    }
}
@media (min-width:62em) {
    /* Media Query : Large */
    .cards-media-gallery {
        min-height: 350px;
    }
    .cards-nav-thumb img {
        min-height: 168px;
    }
}
@media (max-width:47.9375em) {
    /* Media Query : Tablet */
    .cards-media-gallery {
        min-height: 327px;
    }
}
@media (max-width: 39.9375em) {
    /* Media Query : Mobile */
    .cards-nav-wrapper {
        padding: 0;
    }
    .cards-nav-wrapper.active {
        background-color: var(--neutral-cool-light);
    }
    .cards-nav-wrapper+.cards-nav-wrapper>.cards-nav-link,
    .cards-nav-wrapper.active>.cards-nav-link:first-of-type {
        border-top: 1px solid var(--neutral-dark);
    }
    .cards-nav-link {
        margin: 0 2em;
        padding: .5em 0;
        text-align: left;
    }
    .cards-nav-wrapper.active>.cards-nav-link,
    .cards-nav-wrapper.active+.cards-nav-wrapper>.cards-nav-link:first-of-type {
        margin: 0;
    }
    .cards-nav-wrapper.active>.cards-nav-link>a>div,
    .cards-nav-wrapper.active+.cards-nav-wrapper>.cards-nav-link:first-of-type>a>div {
        margin: 0 2em;
    }
    .cards-nav-link span:not(.coming-soon):after {
        position: absolute;
        top: 0.4em;
        right: 2em;
        display: inline-block;
        width: 0;
        height: 0;
        margin-top: .5em;
        content: '';
        border-top: 8px solid var(--primary-blue);
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
    }
    .cards-nav-wrapper.active .cards-nav-link span:not(.coming-soon):after {
        border-top: 0;
        border-bottom: 8px solid var(--primary-blue);
    }
    .coming-soon {
        display: block;
        color: var(--auxiliary-red);
    }
    .cards-nav-close span:first-child:after {
        position: relative;
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 10px;
        content: '';
        border-top: 0;
        border-right: 8px solid transparent;
        border-bottom: 8px solid var(--primary-blue);
        border-left: 8px solid transparent;
    }
    .cards-nav-content+.cards-nav-link,
    .cards-content+.cards-nav-link {
        border-top: 1px solid var(--neutral-medium);
    }
    .cards-nav-link>a>div {
        border: none;
    }
    .cards-nav-thumb+div>span {
        font-size: 1.285714285714286em; /*18px*/
        display: inline-block;
        padding-right: 1.8em;
        width: 100%;
    }
    h2.cards-intro-header {
        background-color: var(--neutral-cool-light);
    }
    .cards-media-gallery {
        min-height: 160px;
    }
    .cards-intro-summary {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
    }
}
@media screen and (min-width: 106.25em) {
    /* Media Query : X Large */
    .cards-nav-content:before,
    .cards-content:before,
    .module-white-bg .cards-content:before,
    .module-grey-bg .cards-content:before {
        margin-left: -850px;
    }
}


/*
 * Ships : Side by Side Module
 */
.sbs-nav .tab-nav-menu {
    text-align: left;
    white-space: normal;
}
.sbs-nav .tab-nav-wrapper.auto-active .tab-nav-menu > .tab-nav-link {
    display: block;
    padding: .3125em .9375em;
    font-size: 14px;
}
.sbs-nav .tab-nav-row {
    text-align: left;
}
.sbs-content-wrapper {
    margin-bottom: 20px;
}
.sbs-content,
.sbs-nav-content {
    display: none;
}
.sbs-content.active {
    display: block;
}
.sbs-content.last {
    display: block;
}
.sbs-nav>.tab-nav-wrapper {
    font-family: var(--red-hat-text);
    left: inherit;
    width: 100%;
    margin-left: inherit;
    padding: 5px 10px;
}
.sbs-mobile-nav>div {
    padding: .5em 0;
    text-align: left;
}
.sbs-nav-close a {
    /* turn off - PCSIWSC-1294 */
    display: none;
}
.sbs-mobile-nav a,
.sbs-nav-close a {
    text-decoration: none;
}
.sbs-mobile-nav a {
    display: block;
}
.sbs-mobile-nav>div {
    margin: 0 2em;
}
.sbs-mobile-nav a span {
    display: inline-block;
    padding-right: 1.8em;
    width: 100%;
}
.sbs-mobile-nav span:not(.coming-soon):after {
    position: absolute;
    right: 2em;
    display: inline-block;
    width: 0;
    height: 0;
    margin-top: .5em;
    content: '';
    border-top: 8px solid var(--primary-blue);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
}
.sbs-content.active .sbs-mobile-nav span:not(.coming-soon):after {
    border-top: 0;
    border-bottom: 8px solid var(--primary-blue);
}
.sbs-content+.sbs-content>.sbs-mobile-nav>div {
    border-top: 1px solid var(--neutral-dark);
}
.sbs-nav-close span:first-child:after {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 10px;
    content: '';
    border-top: 0;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--primary-blue);
    border-left: 8px solid transparent;
}
/* PDP Overwrite (sbs only) */

.sbs-wrapper .product-options-wrapper {
    padding-bottom: 5px;
}
.sbs-overview h2 {
    margin-top: 0;
}
.sbs-scroll-btn {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    border: none;
    background: transparent;
}
.sbs-wrapper .carousel-container {
    padding: 0;
}
.sbs-hidden {
    visibility: hidden;
    height: 0;
}
.sbs-media-gallery .not-ready .slideshow-item {
    /* this is to prevent stutter on init */
    position: absolute;
}
.sbs-nav.pad-50 {
    padding-top: 50px;
}
.sbs-wrapper {
    position: relative;
}
.sbs-intro-summary {
    padding: .5em 2.8em 1.6em;
}
.sbs-intro-summary > p {
    margin: 0;
}
h2.sbs-intro-header {
    position: relative;
    padding-left: 1.6em;
    padding-right: 1.6em;
}
@media (min-width:62em) {
    /* Media Query : Large */
    .sbs-media-gallery {
        min-height: 406px;
    }
}
@media (max-width: 61.9375em) {
    /* Media Query : Desktop */
    .sbs-media-gallery {
        min-height: 312px;
    }
    .sbs-secondary-img {
        visibility: hidden;
        height: 0;
    }
}
@media (max-width:47.9375em) {
    /* Media Query : Tablet */
    .sbs-media-gallery {
        min-height: 230px;
    }
    .sbs-wrapper .tab-nav-row {
        /* height: 160px; */
    }
}
@media (max-width: 39.9375em) {
    /* Media Query : Mobile */
    h2.sbs-intro-header {
        background-color: var(--neutral-cool-light);
    }
    .sbs-wrapper .product-options-wrapper {
        padding-bottom: 20px;
    }
    .sbs-wrapper .sbs-overview p:first-child {
        margin-top: 0;
    }
    .sbs-intro+div {
        padding: 0;
    }
    .sbs-content {
        display: block;
    }
    .sbs-nav {
        padding-top: 0;
    }
    .sbs-nav-content {
        padding: 0 15px;
    }
    .sbs-content.active {
        background-color: var(--neutral-cool-light);
    }
    .sbs-content.active .sbs-nav-content {
        display: block;
    }
    .sbs-content.active>.sbs-mobile-nav>div {
        margin: 0;
        border-top: 1px solid var(--neutral-dark);
    }
    .sbs-content.active>.sbs-mobile-nav>div article {
        margin: 0 2em;
    }
    .sbs-content.active+.sbs-content>.sbs-mobile-nav>div {
        margin: 0;
    }
    .sbs-content.active+.sbs-content>.sbs-mobile-nav>div article {
        margin: 0 2em;
    }
    .sbs-media-gallery {
        min-height: 160px;
    }
    .sbs-intro-summary {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
    }
    .sbs-media > ul {
        margin-top: 0;
    }
}
@media (min-width:40em) {
    .sbs-content.active .sbs-nav-content {
        overflow: visible!important;
    }
    .sbs-content.active>.sbs-nav-content {
        display: block;
    }
    .sbs-content-wrapper {
        min-height: 400px;
    }
    .sbs-overview > h3 {
        margin-top: 5px;
    }
}


/*
 * Global (All Ships Modules)
 */
.cards-nav-close.hidden-xs-down span {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    background: url(../images/icons/close_n.svg);
}
.product-gallery-buttons {
    color: var(--primary-blue);
}
.product-gallery-buttons.active {
    color: var(--neutral-black);
}
.product-gallery-buttons:hover > span,
.product-gallery-buttons:focus > span {
    text-decoration: underline;
}
.product-gallery-buttons > .svg-icon {
    height: 20px;
    width: 20px;
    margin: 5px;
}
.product-gallery-buttons > .svg-icon.svg-icon-audio-desc {
    margin: 5px;
}
.svg-icon.svg-icon-deckplan, .svg-icon.svg-icon-bridge-cam, .svg-icon.svg-icon-find-cruise {
    color: var(--primary-blue);
    margin-right: 5px;
}
.svg-icon-flag-new, .svg-icon-flag-exclusive, .svg-icon-flag-coming-soon {
    width: 60px;
    height: 60px;
    float: left;
}
.svg-icon.svg-icon-audio-desc {
    width: 20px;
    height: 20px;
    margin: 0;
}
.wide-features .product-features-column.light-border-left {
    border-color: var(--neutral-white);
}
.module-template:before {
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
@media (min-width:40em) {
    /* Media Query : Desktop */
    /* module bg color */
    .module-white-bg {
        background-color: var(--neutral-white);
    }
    .module-white-bg .wide-media li.active {
        background-color: var(--neutral-white);
    }
    .module-grey-bg {
        background-color: var(--neutral-cool-light);
    }
    .module-grey-bg .cards-nav-thumb + div {
        background-color: var(--neutral-white);
    }
    .module-template:before {
        background: inherit;
        left: 50%;
        margin-left: -50%;
        margin-left: calc(-100vw / 2);
        max-width: 1700px;
        width: 100vw;
    }
}
@media (max-width: 39.9375em) {
    /* Media Query : Mobile */
    .ship-icons {
        display: block;
        width: 30px;
        height: 30px;
        margin: 0 auto 1em auto;
    }
    button.product-gallery-buttons {
        width: 75px;
        margin: -5px;
    }
    button.product-gallery-buttons span:first-of-type {
        display: block;
        margin: 0 auto;
    }
    .svg-icon-flag-new, .svg-icon-flag-exclusive, .svg-icon-flag-coming-soon {
        width: 50px;
        height: 50px;
    }
}


/*
 * Pause/Play Button
 */
/* tpl-v2 */
button.pause-play-btn {
    z-index: 150;
    left: 15px;
    top: 45%;
    position: absolute;
    border: none;
    padding: .5em;
    margin: 0;
    background: var(--primary-blue);
}
button.pause-play-btn > span {
    margin: 0;
}
@media (min-width: 640px) and (max-width: 939px) {
    button.pause-play-btn {
        top: 50%;
    }
}
@media (min-width: 990px) {
    button.pause-play-btn {
        left: 50%;
        margin-left: -470px;
    }
}

/* slide-show */
.slideshow-container button.pause-play-btn {
    top: 65%;
    left: 30px;
}
@media (min-width: 440px) {
    .slideshow-container button.pause-play-btn {
        top: 78%;
    }
}
@media (min-width: 990px) {
    .slideshow-container button.pause-play-btn {
        top: 85%;
        left: 60%;
    }
}

/* ships slide-show */
.product-options-photo button.pause-play-btn {
    padding: 6.5px;
    left: 15px;
    bottom: 5%;
    top: auto;
    margin-left: 0;
}
.full-width .product-options-photo .slideshow-controls {
    top: 49%;
}
.full-width .product-options-photo .slideshow-prev {
    left: 15px;
}
.full-width .product-options-photo .slideshow-next {
    right: 15px;
}
@media (min-width: 60em) {
    .product-options-photo button.pause-play-btn {
        left: 5%;
    }
    .full-width .product-options-photo .slideshow-prev {
        left: 5%;
    }
    .full-width .product-options-photo .slideshow-next {
        right: 5%;
    }
}

/*
 * Content Hub
 */
.container > .headline-bar:first-child {
    padding-top: 0;
}
.hub-video-description p {
    margin: 0;
}
.hub-share-bar .share-text, .hub-ad-bar .transcript-text {
    font-family:  var(--red-hat-text);
    vertical-align: middle;
}
.hub-article.container {
    max-width: 640px;
}
.hub-article-content p:first-of-type {
    margin-top: 0;
}
.hub-article-content p:last-of-type {
    margin-bottom: 0;
}
.hub-share-bar .share-text {
    font-family:  var(--red-hat-text);
}
.hub-share-bar a, .hub-ad-bar a {
    text-decoration: none;
    display: inline-block;
}
.hub-share-bar .svg-icon {
    height: 20px;
    width: 20px;
    margin: 0;
}
.hub-share-bar .svg-icon.svg-icon-facebook {
    color: var(--primary-blue);
}
.hub-share-bar .svg-icon.svg-icon-pinterest {
    color: var(--error);
}
.hub-share-bar .svg-icon.svg-icon-email {
    color: var(--primary-blue);
}
.hub-share-bar .svg-icon.svg-icon-twitter {
    color: var(--auxiliary-blue);
}
.hub-share-bar a {
    padding: 5px;
}
.hub-share-bar a:last-of-type {
    padding-right: 0;
}
@media screen and (min-width: 48em) {
    .hub-article .hub-share-bar a {
        display: block;
        padding: 5px 0;
    }
    .hub-article .hub-article-share {
        padding: 0;
        width: auto;
        position: absolute;
        margin-left: -50px;
        top: 15px;
    }
    .hub-video-share {
        padding-right: 0;
    }
}

/**
 * Owl Carousel
 */

/* core (from plugin) */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1; }
.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
.owl-carousel .owl-item img {
    display: block;
    width: 100%; }
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none; }
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
.owl-carousel.owl-loaded {
    display: block; }
.owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
.owl-carousel.owl-hidden {
    opacity: 0; }
.owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
.owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
.owl-carousel.owl-rtl {
    direction: rtl; }
.owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
    display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
    z-index: 0; }

.owl-carousel .owl-animated-out {
    z-index: 1; }

.owl-carousel .fadeOut {
    animation-name: fadeOut; }

@keyframes fadeOut {
    0% {
        opacity: 1; }
    100% {
        opacity: 0; } }

/*
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
    transition: height 500ms ease-in-out; }

/*
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 *  Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: var(--neutral-black);
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none; }

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%; }

/* theme default */
.owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
.owl-nav .disabled {
    opacity: 0.5;
    cursor: default;
}
.owl-nav.disabled+.owl-dots {
    margin-top: 10px;
}
.owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
.owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    vertical-align: middle;
}
.owl-dots .owl-dot button {
    width: 12px;
    height: 12px;
    margin: 5px 12px;
    background: var(--neutral-cool-medium);
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
    border: 1px solid var(--neutral-cool-medium);
}
.owl-dots .owl-dot.active button,
.owl-dots .owl-dot:hover button {
    background: var(--neutral-white);
}

/* custom */
.carousel-container {
    position: relative;
    padding: 0 15px;
}
.carousel-container.owl-bg-slideshow {
    padding: 0;
}
.horz-carousel .owl-stage {
    margin-left: -50px;
}
.owl-nav button {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: 0;
    z-index: 100;
    top: 45%;
    margin: 0;
    padding: 0;
}
.owl-nav button.disabled {
    opacity: 0.4;
    cursor: default;
}
.owl-nav button.owl-next {
    right: 0;
    float: right;
}
.owl-nav button.owl-prev {
    left: 0;
    float: left;
}
.owl-horz-carousel .owl-nav button {
    top: 30%;
    float: none;
    margin: .25em .50em;
    padding: 4px;
}
.owl-bg-slideshow .owl-nav button {
    top: 40%;
}
.owl-bg-slideshow .owl-nav button.owl-next {
    right: 3%;
}
.owl-bg-slideshow .owl-nav button.owl-prev {
    left: 3%;
}
.carousel-container .owl-dots {
    right: 0;
    left: 0;
    text-align: center;
    bottom: 5%;
    z-index: 99;
}
.owl-dots {
    position: absolute;
    left: 50%;
    margin-left: -50%;
    width: 100%;
    bottom: 3%;
    z-index: 1;
}
.owl-media {
    position: absolute;
    left: 2%;
    bottom: 3%;
    z-index: 2;
}
.owl-media.disabled {
    display: none;
}
.owl-play-pause {
    border: none;
    background: transparent;
}
.owl-play-pause .pause {
    background-position: center;
    width: 10px;
    height: 12px;
    border-right: 4px solid var(--neutral-white);
    border-left: 4px solid var(--neutral-white);
}
.owl-play-pause .play {
    background-position: center;
    width: 10px;
    height: 12px;
    border-top: 6px solid transparent;
    border-left: 10px solid var(--neutral-white);
    border-bottom: 6px solid transparent;
}
.owl-bg-slideshow .owl-media {
    bottom: 3px;
}
.owl-bg-slideshow .owl-media button {
    margin: 0;
}
.owl-bg-slideshow .owl-dots {
    bottom: 4%;
    margin-left: 0;
    position: relative;
    background: var(--neutral-dark);
    padding: 4px 0;
}
.owl-bg-slideshow .owl-media button {
    left: 3%;
    bottom: 10%;
}
.owl-item > div {
    width: 100%;
    padding: 1px;
}
.owl-bg-slideshow .slide-img > img {
    margin-left: 0;
}
.owl-bg-slideshow .owl-item > div {
    padding: 0;
}
.carousel-container.owl-bg-slideshow .owl-dots {
    z-index: 1;
}
.owl-bg-slideshow .svg-icon.svg-icon-pause,
.owl-bg-slideshow .svg-icon.svg-icon-arrow {
    color: var(--neutral-white);
    width: 11px;
    height: 11px;
    margin-bottom: 2px;
}
.owl-bg-slideshow a,
.owl-bg-slideshow picture {
    display: block;
}
.owl-bg-slideshow a:focus {
    outline-offset: -3px;
}
.owl-ratio-container {
    position: relative;
    padding-bottom: calc(57.2916% + 30px);
    height: 0;
    overflow: hidden;
    margin-top: -1px;
}
.owl-ratio-container.owl-single-slide {
    padding-bottom: calc(57.2916%);
}
.owl-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
/* 640 */
@media screen and (min-width: 40em) {
    .horz-carousel .owl-stage {
        margin-left: 0;
    }
    .owl-ratio-container {
        padding-bottom: calc(57.27% + 30px);
    }
    .owl-ratio-container.owl-single-slide {
        padding-bottom: calc(57.27%);
    }
}
@media screen and (min-width: 48em) {
    .owl-nav button.disabled {
        display: none;
    }
}
/* 960 */
@media screen and (min-width: 60em) {
    .owl-bg-slideshow .owl-media button {
        bottom: 20%;
    }
    .owl-ratio-container {
        padding-bottom: calc(31.5625% + 30px);
    }
    .owl-ratio-container.owl-single-slide {
        padding-bottom: calc(31.5625%);
    }
}
/* 992 */
@media screen and (min-width: 62em) {
    .owl-nav button {
        position: absolute;
        display: block;
        margin: .25em .50em;
        padding: 4px;

    }
    .owl-nav button.owl-next {
        right: -2%;
        float: none;
    }
    .owl-nav button.owl-prev {
        left: -2%;
        float: none;
    }

}
/* 1072 */
@media screen and (min-width: 67em) {
    .owl-nav button.owl-next {
        right: -6%;
    }
    .owl-nav button.owl-prev {
        left: -6%;
    }
}
/* chevron arrows */
.svg-icon.icon-chevron {
    width: 20px;
    height: 20px;
    display: inline-block;
}
.svg-icon.icon-chevron.left {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.svg-icon.icon-chevron.circle {
    width: 40px;
    height: 40px;
    color: var(--primary-blue);
    background-color: var(--neutral-white);
    opacity: .9;
    padding: 8px 5px 8px 8px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid var(--neutral-medium);
}
.owl-nav button:hover .svg-icon.circle, .owl-nav button:focus .svg-icon.circle {
    background-color: var(--primary-blue);
    color: var(--neutral-white);
}
.owl-nav button.disabled:hover .svg-icon.circle, .owl-nav button.disabled:focus .svg-icon.circle {
    background-color: initial;
    color: var(--primary-blue);
}

/* ADA : h2 focus */
h2[tabindex="-1"]:focus {
    outline: none;
}
/* destinations */
.destinations-text p:first-of-type {
    margin-top: 0;
}
.destinations-text p:last-of-type {
    margin-bottom: 0;
}
.destinations-links a {
    display: inline-block;
}
.destinations-links a > span {
    display: block;
}
.destinations-links > div {
    text-align: center;
}
.destinations-links .svg-icon {
    margin: 0 0 5px 0;
    width: 30px;
    height: 30px;
}
.cta-container {
    background-color: var(--auxiliary-red-dark);
}
.cta-btn > a:first-of-type {
    font-family:  var(--red-hat-text);
    letter-spacing: 1px;
}
.cta-btn > a + a {
    display: block;
    font-family:  var(--red-hat-text);
    letter-spacing: 1px;
}
.cta-summary {
    text-align: center;
}
.cta-descr p {
    margin: .5em 0;
}
.cta-img > img {
    max-width: 170px;
}
.destinations-carousel:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
}
.destinations-carousel,
.destinations-carousel > h2 {
    position: relative;
}
.destinations-carousel:before {
    left: 50%;
    width: 100vw;
    max-width: 1700px;
    margin-left: -50%;
    margin-left: calc(-100vw / 2);
    background-repeat: no-repeat;
    background-position: center center;
    background: inherit;
}
.destinations-carousel .floating-cards {
    background: transparent;
}
.destinations-carousel .floating-cards article {
    background: var(--neutral-white);
}
.destinations-carousel .carousel-container {
    padding-right: 0;
}
.destinations-carousel.dark-bg .destinations-carousel-link a {
    padding: 5px;
    background: var(--transparent-medium);
    color: var(--neutral-white);
}
.destinations-carousel.light-bg .destinations-carousel-link a {
    padding: 5px;
    background: var(--neutral-white);
    color: var(--neutral-black);
}
.destinations-carousel-link > p {
    text-align: center;
    margin: 0;
    padding: 1em;
}
.destinations-carousel.dark-bg > .headline-bar > *,
.destinations-carousel.dark-bg > .carousel-headline > * {
    color: var(--neutral-white);
}
.destinations-carousel > .carousel-headline {
    position: relative;
}
.destinations-carousel > .carousel-headline > .carousel-intro-header {
    padding-left: 1.6em;
    padding-right: 1.6em;
}
.destinations-carousel > .carousel-headline > .carousel-intro-summary {
    padding-left: 2.8em;
    padding-right: 2.8em;
}
@media (min-width:40em) {
    .destinations-carousel .carousel-container {
        padding-right: 15px;
    }
}
@media (min-width:48em) {
    .destinations-links .svg-icon {
        margin: 0 10px 0 0;
    }
    .destinations-links a > span {
        display: inline;
    }
    .cta-btn > a + a {
        margin-left: 30px;
        display: inline;
    }
    .cta-btn > a:first-of-type {
        margin-bottom: 0;
    }
    .cta-summary {
        text-align: left;
    }
}
@media (min-width:53.750em) {
    .destinations-links > div:first-of-type {
        text-align: right;
    }
    .destinations-links > div:last-of-type {
        text-align: left;
    }
}
@media (max-width: 61.9375em) {
    .destinations-carousel.dark-bg {
        background: rgb(79,77,106)!important;
        background: -webkit-linear-gradient(rgba(79,77,106,1) 0%, rgba(155,134,138,1) 41%, rgba(218,173,149,1) 100%)!important;
        background: -o-linear-gradient(rgba(79,77,106,1) 0%, rgba(155,134,138,1) 41%, rgba(218,173,149,1) 100%)!important;
        background: linear-gradient(rgba(79,77,106,1) 0%, rgba(155,134,138,1) 41%, rgba(218,173,149,1) 100%)!important;
    }
    .destinations-carousel.light-bg {
        background: rgb(192,209,225)!important;
        background: -webkit-linear-gradient(rgba(192,209,225,1) 0%, rgba(225,228,223,1) 44%, rgba(237,217,191,1) 100%)!important;
        background: -o-linear-gradient(rgba(192,209,225,1) 0%, rgba(225,228,223,1) 44%, rgba(237,217,191,1) 100%)!important;
        background: linear-gradient(rgba(192,209,225,1) 0%, rgba(225,228,223,1) 44%, rgba(237,217,191,1) 100%)!important;
    }
}
@media screen and (min-width: 106.25em) {
    .destinations-carousel:before,
    .module-template:before {
        margin-left: -850px;
    }
}
.full-width .sticky-wrapper > .sticky-bar.cta-grey-bg:before,
.full-width .cta-wrapper > .cta-bar.cta-grey-bg:before
{
    background: var(--neutral-cool-light);
}

/* Automatic Activation Tab Nav */
.tab-nav-wrapper.auto-active .tab-nav-menu {
    font-size: 1.143em;
}
.tab-nav-wrapper.auto-active .tab-nav-link.active,
.tab-nav-wrapper.auto-active .tab-nav-link.active:hover,
.tab-nav-wrapper.auto-active .tab-nav-link.active:focus {
    background-color: var(--neutral-dark);
    color: var(--neutral-white);
}
.tab-nav-wrapper.auto-active .tab-nav-link:hover,
.tab-nav-wrapper.auto-active .tab-nav-link:focus {
    background-color: var(--neutral-cool-dark);
    color: var(--neutral-white);
}
@media screen and (min-width: 48em) {
    .tab-nav-wrapper.auto-active .tab-nav-menu > .tab-nav-link {
        display: inline-block;
    }
}

/* Live Text Flag */
.live-text-flag {
    background-color: var(--auxiliary-blue);
    padding: 2px 8px 2px 20px;
    position: relative;
    margin-right: 10px;
    font-size: 14px;
}
.live-text-flag,
.live-text-flag + span,
.live-text-flag + p {
    vertical-align: middle;
    display: inline;
}
.live-text-flag:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid var(--neutral-white);
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

/* Lazy Load */
.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -ms-transition: opacity 300ms;
    -o-transition: opacity 300ms;
}

/* Carousel ratio scaling for lazy load */
.carousel-container .article-media {
    position: relative;
}
.carousel-container .article-media:after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
    padding-bottom: 56.25%;
}
.carousel-container .article-media > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}
/* New button for Deck Plans*/
.deck-btn{
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    margin-left: 1em;
    border-radius: 50%;
    background: var(--primary-blue);
    top: -2px;
}
.deck-btn:hover{
    background-color: var(--primary-blue);
}
.deck-plan-btn{
    position: absolute;
    height: 60%;
    width: 60%;
    top: 7px;
    left: 8px;
    fill: white;
}
.deck-plan-list{
    max-width: 80%;
}

/* ugc gallery */
.ugc-skeleton {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    animation-duration: 3.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shine;
    animation-timing-function: linear;
    background: linear-gradient(to right, var(--neutral-cool-light) 8%, var(--neutral-light) 18%, var(--neutral-cool-light) 33%);
    background-size: 1000px 104px;
}
.ugc-skeleton-row {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[5];
    grid-template-columns: repeat(5, 1fr);
}
.ugc-skeleton-btn {
    height: 45px;
    background: transparent;
    border-top: 10px solid var(--neutral-white);
}
/* ie grid */
.ugc-skeleton-row div,
.ugc-skeleton-row.top {
    -ms-grid-row: 1;
}
.ugc-skeleton-row.bottom {
    -ms-grid-row: 2;
}
.ugc-skeleton-btn {
    -ms-grid-row: 3;
}
.ugc-skeleton > div,
.ugc-skeleton-row div:nth-child(1) {
    -ms-grid-column: 1;
}
.ugc-skeleton-row div:nth-child(2) {
    -ms-grid-column: 2;
}
.ugc-skeleton-row div:nth-child(3) {
    -ms-grid-column: 3;
}
.ugc-skeleton-row div:nth-child(4) {
    -ms-grid-column: 4;
}
.ugc-skeleton-row div:nth-child(5) {
    -ms-grid-column: 5;
}
/* responsive */
.ugc-skeleton-row div > svg {
    height: 1px;
    overflow: visible;
    padding-top: 100%;
    width: 100%;
}
/* border overlays */
.ugc-skeleton-row div {
    background: transparent;
    border: 5px solid var(--neutral-white);
}
.ugc-skeleton-row.top div {
    border-top: 0;
}
.ugc-skeleton-row.bottom div {
    border-bottom: 0;
}
.ugc-skeleton-row div:first-child{
    border-left: 0;
}
.ugc-skeleton-row div:last-child {
    border-right: 0;
}
/* shine animation */
@keyframes shine{
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}
/* virtual tours */
.vtour-container {
    position: relative;
}
.vtour-logo,
.vtour-title,
.vtour-play {
    color: var(--neutral-white);
}
.vtour-logo svg {
    width: 50px;
    height: 50px;
}
.vtour-logo span,
.vtour-play span {
    display: block;
}
.vtour-play svg {
    width: 40px;
    height: 40px;
}
.vtour-title {
    margin: 10px 0 20px;
}
.vtour-title span {
    border-top: 3px solid var(--neutral-white);
    border-bottom: 3px solid var(--neutral-white);
    padding: 10px 0;
    letter-spacing: 3px;
    text-shadow: 1px 2px 2px var(--transparent-medium);
}
.vtour-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.vtour-img a,
.vtour-img picture {
    display: block;
}
@media (min-width: 40em) {
    .vtour-title {
        margin: 60px 0 30px;
    }
    .vtour-title span {
        font-size: 2.642857142857143em;
        padding: 5px 0;
    }
    .vtour-play svg {
        width: 85px;
        height: 85px;
        padding-bottom: 10px;
    }
}
/* virtual tours mfp */
.vtours-lightbox .mfp-content {
    height: 96%;
}
.vtours-lightbox .mfp-content .mfp-iframe-scaler iframe {
    border: 0;
}
@media (min-width: 40em) {
    .vtours-lightbox .mfp-content {
        height: auto;
    }
}
/* Sign up for special offers snippet */

.offerUp{
    border: 1px solid var(--neutral-cool-light);
    border-radius: 10px;
    box-shadow: 0px 3px 6px var(--neutral-cool-light);
    padding-top:20px;
}
.offerUp h3{
    color:var(--primary-blue);
    font-size: 22px;
}
.offerUp a{
    padding: 6px 32px;
    font-size: 19px;
}
.svg-rotate-up{
    transform: rotate(-21deg);
    height: 98px;
    width: 85px;
    color: var(--primary-blue);
    position: absolute;
    bottom: 12px;
    left: 40px;
}
.svg-rotate-down{
    height: 80px;
    width: 80px;
    color: var(--neutral-cool-light);
    transform: rotate(10deg);
    margin-top: -16px;
}
.offerUp .col-lg-pad-0-left{
    margin-bottom: 15px;
}
.offer-link{
    margin-top: 6px;
}
@media only screen and (max-width: 767px) and (orientation:portrait) {
    .svg-rotate-up {
        width: 50px;
        left: 30px;
        top: -28px;
    }
    .svg-rotate-down {
        width: 50px;
        margin-top: -8px;
    }
    .offerUp h3{
        font-size: 20px;
    }
    .offerUp .col-lg-pad-0-left{
        margin-bottom: 25px;
    }
}

/*
* New Vertical Layout: Card Layout
*/

/**
* Background Styles
*/
.bg-default,
.bg-white {
    background: var(--neutral-white); /*var updated*/
}
.bg-sea-form {
    background: var(--neutral-warm-light); /*var updated*/
}
.bg-light-blue {
    background: var(--auxiliary-blue-extra-light); /*var updated*/
}
.bg-cool-light {
    background: var(--neutral-cool-light); /*var updated*/
}
.bg-warm-medium {
    background: var(--neutral-warm-medium); /*var updated*/
}
.bg-warm-light {
    background: var(--neutral-warm-light); /*var updated*/
}

/**
* Flexcard Border Styles
*/
.border-default,
.border-stroke {
    border: solid 1px var(--neutral-medium); /*var updated*/
}
.border-shadow {
    box-shadow: var(--border-shadow); /*var updated*/
}
.border-none {
    border: none;
}

/**
* Flexcards (container) Styles
*/
/*Default*/
.flexcards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 1rem;
    grid-auto-rows: auto;
    padding: 0 0 15px 0;
}
.flexcards + .flexcards {
    margin-top: 15px;
}
.flexcards.flexcard-4-col,
.flexcards.flexcard-3-col,
.flexcards.flexcard-2-col,
.flexcards.flexcard-1-col {
    grid-template-columns: repeat(1, minmax(220px, 1fr));
}

/*Breakpoint:640px*/
@media screen and (min-width: 40em) {
    .flexcards {
        padding: 15px;
    }
    .flexcards.flexcard-4-col,
    .flexcards.flexcard-3-col,
    .flexcards.flexcard-2-col {
        grid-template-columns: repeat(2, minmax(220px, 1fr));
    }
    .flexcards.flexcard-1-col {
        grid-template-columns: repeat(1, minmax(220px, 1fr));
    }
}

/*Breakpoint:768px*/
@media screen and (min-width: 48em) {
    .flexcards.flexcard-4-col {
        grid-template-columns: repeat(4, minmax(150px, 1fr));
    }
    .flexcards.flexcard-3-col {
        grid-template-columns: repeat(3, minmax(220px, 1fr));
    }
    .flexcards.flexcard-2-col {
        grid-template-columns: repeat(2, minmax(220px, 1fr));
    }
    .flexcards.flexcard-1-col {
        grid-template-columns: repeat(1, minmax(220px, 1fr));
    }
}

/* BG Color All flexCards */
.flexcard-bg-default .flexcard,
.flexcard-bg-white .flexcard {
    background: var(--neutral-white); /*var updated*/
}
.flexcard-bg-sea-form .flexcard {
    background: var(--neutral-warm-light); /*var updated*/
}
.flexcard-bg-light-blue .flexcard {
    background: var(--auxiliary-blue-extra-light); /*var updated*/
}
.flexcard-bg-cool-light .flexcard {
    background: var(--neutral-cool-light); /*var updated*/
}
.flexcard-bg-warm-medium .flexcard {
    background: var(--neutral-warm-medium); /*var updated*/
}
.flexcard-bg-warm-light .flexcard {
    background: var(--neutral-warm-light); /*var updated*/
}

/**
* Flexcard - Border Style
*/
/*Default*/
.flexcard-border-default .flexcard,
.flexcard-border-stroke .flexcard,
.flexcard-border-shadow .flexcard,
.flexcard-border-none .flexcard {
    box-shadow: var(--border-shadow); /*var updated*/
}
/*Viewport: 640px & above*/
@media screen and (min-width: 40em) {
    .flexcard-border-default .flexcard,
    .flexcard-border-stroke .flexcard {
        border: solid 1px var(--neutral-medium); /*var updated*/
        box-shadow: none;
    }
    .flexcard-border-shadow .flexcard {
        box-shadow: var(--border-shadow); /*var updated*/
    }
    .flexcard-border-none .flexcard {
        border: none;
        box-shadow: none;
    }
}

/**
* Flexcard Styles
*/
.flexcard {
    padding: 15px;
    text-align: center;
}
.flexcard sup {
    font-size: 60%;
    vertical-align: baseline;
    top: -5px;
}

/* Flexcard Colors */
.flexcard .color-green {
    color: var(--neutral-medium);
}


.flexcard .color-red {
    color: var(--auxiliary-red);
}

.flexcard .color-grey {
    color: var(--neutral-dark);
}

/* Title Styles */
.flexcard h3 {
    font-weight: 500;
    font-size: 1.4285714285714286em;
    line-height: 1.2857142857142858em;
    letter-spacing: 0px;
    text-transform: capitalize;
    color: var(--primary-dark); /*var updated*/
    margin: 0;
}

/* Subtitle Styles */
.flexcard h4 {
    font-size: 1.1428571428571428em;
    line-height: 1.2857142857142858em;
    letter-spacing: 0px;
    color: var(--primary-dark); /*var updated*/
    padding: 0 10px;
    margin: 0;
}
.flexcard h5 {
    font-weight: 500;
    font-size: 1em;
    letter-spacing: 0px;
    margin: 0 0 10px;
}

/* Pricing */
.flexcard-pricing {
    font-size: 1.357142857142857em;
    margin: 0;
}

/* Flexcard Image */
.flexcard-img {
    margin: 0;
}
.flexcard-img>a {
    display: block;
}
.shape-rounded img {
    object-fit: cover;
    width: 175px;
    height: 175px;
    border-radius: 50%;
}
.flexcard-img + h3 {
    margin-top: 0px;
}

/* Flexcard Content */
.flexcard p {
    text-align: left;
}
.flexcard p.align-center {
    text-align: center;
}
@media screen and (min-width: 40em) {
    .flexcard p {
        padding: 0 10px 0 10px;
    }
}

/* Flexcard CTA */
.flexcard-cta {
    padding: 0 15px;
}
.flexcard-cta .button {
    display: block;
    max-width: 280px;
    margin: 0 auto;
}
@media screen and (min-width: 40em) {
    .flexcard-cta {
        padding: 0;
    }
}

/* Flexcard Medallion App */
.flexcard-medallion-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
}
.flexcard-medallion-app img {
    width: 100%;
    height: 100%;
}
.app-store-btns.stack-row {
    max-width: 300px;
}
.app-store-btns.stack-column {
    max-width: 140px;
}
.stack-row {
    display: flex;
    flex-direction: row;
}
.stack-row a + a {
    margin-left: 15px;
}
.stack-column {
    display: flex;
    flex-direction: column;
}
.stack-column a + a {
    margin-top: 10px;
}

/* Flexcard List */
.flexcard ul, .flexcard ol {
    padding: 0;
    text-align: left;
}

.flexcard ul.align-center, .flexcard ol.align-center {
    text-align: center;
}

.flexcard ul.align-right, .flexcard ol.align-right {
    text-align: right;
}

@media screen and (min-width: 40em) {
    .flexcard ul, .flexcard ol {
        padding: 0 10px;
    }
}

/* Flexcard List With Icons */
.flexcard ul.list-with-icons li {
    display: grid;
    grid-template-columns: 18px auto;
    grid-gap: 3px;
}
.flexcards .svg-icon {
    height: 18px;
    width: 18px;
}

/* Flexcard List With Separator */
.flexcard .list-with-separator {
    list-style: none;
}
.flexcard .list-with-separator.align-center {
    text-align: center;
}
.list-with-separator li {
    padding: 0.25em 0;
    border-bottom: solid 1px var(--neutral-cool-medium); /*var updated*/
}
@media screen and (min-width: 40em) {
    .list-with-separator li {
        padding: 0.5em 0;
    }
}

/* Flexcard List With Bullets */
.flexcard .list-with-bullet {
    margin: 15px 0 15px 15px;
}
.list-with-bullet li {
    margin-bottom: 5px;
}
.list-with-bullet li > span {
    display: block;
    margin-left: -7px;
}

/* Flexcard Info Links */
.flexcard .info-link {
    font-weight: 500;
    margin: 0;
}
.info-link > a {
    display: block;
    font-weight: 400;
}

/* Flexcard Links with Separators */
.flexcard .links-with-separator {
    list-style: none;
}
.links-with-separator li {
    display: inline-block;
}
.links-with-separator li + li {
    border-left: solid 1px var(--primary-dark); /*var updated*/
    padding-left: 5px;
    margin-left: 3px;
    line-height: 0.8em;
}

/**
* Flexcard Content Alignment
*/
.flexcards.justify-content .flexcard,
.flexcard.justify-content {
    justify-content: center;
}
.flexcards.content-align-left .flexcard,
.flexcard.content-align-left {
    text-align: left;
}